import React from 'react'

export default ({node}) => {
  let {url} = node
  if (url.slice(-1) === '/') {
    url = url.substr(0, url.length - 1)
  }

  let id = url.split('/').pop()
  const aspectRatio = 16 / 9
  const width = 640
  const height = width / aspectRatio

  if (!id) return (<a className='block text-indigo-600 my-2' href={url}>{url}</a>)

  return (
    <amp-instagram data-shortcode={id} data-captioned width={width} height={height} layout='responsive' />
  )
}
