import React from 'react'
import {graphql} from 'gatsby'
import AmpLayout from '../components/amp/layout'
import AmpImage from '../components/amp/serializers/Image'
import {format} from 'date-fns'
import AmpContent from '../components/amp/content'
import SEO from '../components/seo'
import {toPlainText, buildLinkedDataObj} from '../lib/helpers'

export const query = graphql`
  query AmpBlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: {eq: $id}) {
      id
      _createdAt
      publishedAt
      _updatedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        alt
        asset {
          _id
          url
        }
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
      authors {
        _key
        author {
          _rawBio(resolveReferences: {maxDepth: 5})
          image {
            alt
            asset {
              _id
            }
          }
          name
          slug {
            current
          }
        }
      }
    }
  }
`

const AmpBlogPostTemplate = props => {
  const {data} = props
  const post = data && data.post
  const {title, _rawBody, _rawExcerpt, categories, mainImage, publishedAt, _updatedAt} = post
  const category = categories.length > 0 ? categories[0] : {}

  const postSEO = [
    {property: 'article:published_time', content: publishedAt},
    {property: 'article:modified_time', content: _updatedAt}
  ]

  const ldJson = buildLinkedDataObj(post)

  return (
    <AmpLayout>
      {
        post &&
        <SEO
          title={title || 'Untitled'}
          description={toPlainText(_rawExcerpt)}
          image={mainImage}
          meta={postSEO}
          type={'article'}
          ldJson={ldJson} />
      }
      <article>
        <header className='article-header'>
          <span className='category-title'>{category.title}</span>
          <h1 className='article-title'>{title}</h1>
          <span className='published-date'>{format(publishedAt, 'MMMM Do, YYYY')}</span>
          <AmpImage node={mainImage} className='article-header-image' />
        </header>

        <section className='article-content'>
          {_rawBody && <AmpContent blocks={_rawBody} />}
        </section>
      </article>
    </AmpLayout>
  )
}

export default AmpBlogPostTemplate
