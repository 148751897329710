import React from 'react'
import Image from './Image'
import Instagram from './instagram'
import Video from './video'
import TikTok from './tiktok'
import HTML from './html'
import BlockRenderer from '../../common/Blocks'

const ampSerializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Image,
    block: BlockRenderer,
    tiktok: TikTok,
    youtube: Video,
    custom_html: HTML,
    instagram: Instagram
  }
}

export default ampSerializers
