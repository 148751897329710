import React from 'react'
import getVideoId from 'get-video-id'

export default ({node}) => {
  const {url} = node
  const {id, service} = getVideoId(url)
  const aspectRatio = 16 / 9
  const width = 640
  const height = width / aspectRatio

  switch (service) {
    case 'youtube':
      return (<amp-youtube width={width} height={height} layout='responsive' data-param-modestbranding='1' data-param-rel='1' data-videoid={id} />)
    case 'vimeo':
      return (<amp-vimeo data-videoid={id} layout='responsive' width={width} height={height} />)
    default:
      return (<span>Unsupported video service: {service}</span>)
  }
}
