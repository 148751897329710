import React from 'react'

const AmpHeader = () => {
  return (
    <>
      <header className='amp-header'>
        <div className='amp-header-content container'>
          <div className='site-name'>Tigenz Blog</div>
          <div role='button' on='tap:sidebar1.toggle' tabIndex='0'>
            <svg className='menu-svg fill-current' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><title>Menu</title><path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' /></svg>
          </div>
        </div>
      </header>
      <amp-sidebar id='sidebar1' layout='nodisplay' side='right'>
        <div className='close-sidebar'>
          <span role='button' aria-label='close sidebar' on='tap:sidebar1.toggle' tabIndex='0'>✕</span>
        </div>
        <ul className='sidebar'>
          <li><a href='/'>Home</a></li>
          <li><a href='/category/news'>News</a></li>
          <li><a href='/category/thoughts'>Thoughts</a></li>
        </ul>
      </amp-sidebar>
    </>
  )
}

export default AmpHeader
