import React from 'react'
import clientConfig from '../../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import ampSerializers from './serializers'

const AmpContent = ({blocks}) => (
  <BasePortableText blocks={blocks} serializers={ampSerializers} {...clientConfig.sanity} />
)

export default AmpContent
