import React from 'react'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../../../client-config'

export default ({node, className = 'my-4'}) => {
  if (!node || !node.asset || !node.asset._id) { return null }
  const args = {maxWidth: 1200}
  const fluidProps = getFluidGatsbyImage(node.asset._id, args, clientConfig.sanity)

  return (
    <amp-img
      srcset={fluidProps.srcSet}
      src={fluidProps.src}
      width={1200}
      height={Math.floor(1200 / 1.5)}
      alt={node.alt}
      layout='responsive'
      class={className} />
  )
}
