import React from 'react'
import {Parser} from 'html-to-react'
const parser = new Parser()

export default ({node}) => {
  const {html} = node
  if (!html) return <span />
  if (html.match(/(script|js)/)) return <span>&nbsp;</span>

  const parsed = parser.parse(html)
  return <div className='flex justify-center'>{parsed}</div>
}
