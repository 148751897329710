import React from 'react'
import PropTypes from 'prop-types'
import AmpHeader from './header'
import AmpFooter from './footer'

import '../../styles/amp.css'

const AmpLayout = ({children}) => {
  return (
    <>
      <AmpHeader />
      <main id='content' role='main' className='container pt-16 px-4'>{children}</main>
      <AmpFooter />
    </>
  )
}

AmpLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AmpLayout
