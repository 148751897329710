import React from 'react'

export default ({node}) => {
  const {url} = node
  return (
    <div>
      <a href={url} style={{color: '#00376b', marginTop: '16px'}}>{url}</a>
    </div>
  )
}
