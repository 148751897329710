import React from 'react'

const AmpFooter = () => (
  <footer className='amp-footer'>
    <div className='container'>
      <div className='column-1'>
        <p>Copyright © {new Date().getFullYear()} All rights reserved | Studio 31</p>
      </div>
    </div>
  </footer>
)

export default AmpFooter
